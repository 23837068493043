import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class ReactReduxCorso extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            react_redux: file(relativePath: { eq: "courses/courses-logos/react-redux.png" }) { ...thumb },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },


          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#61dafb',
    separatorHeaderColor2: '#764abc',
    separatorColor1: '#61dafb',
    separatorColor2: '#764abc',
    buttonColor: '#30b3d7',
    iconColor1: '#666',       // summary
    iconColor2: '#764abc',    // faq
    panelBackground1: '#764abc',
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso di formazione su React e Redux',
    title: 'DATA ARCHITECTURES <br /> IN REDUX (ES6) ',
    shortTitle: 'DATA ARCHITECTURES <br /> IN REDUX (ES6) ',
    subtitle: 'Un corso intensivo sulla creazione di architetture solide e scalabili in React, tramite l\'utilizzo del pattern / libreria Redux per la gestione dello stato applicativo',
    image: 'react_redux',
    difficulty: 'Avanzato',
    duration: '8 ore (1g)'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'React fornisce gli strumenti fondamentali per l\'organizzazione della user interface in componenti. Tuttavia, le attività che il front-end deve svolgere sono sempre più complesse e le interfacce utente (UI), di conseguenza, stanno diventando sempre più sofisticate: gestione di un data-flow molto articolato, componenti che devono rimanere in sync tra di loro, integrazione unit test, refactoring e debug sono solo alcune delle attività ricorrenti che uno sviluppatore front-end deve affrontare quotidianamente. <br /><br />Redux, libreria nata per requisiti complessi in contesti quali Facebook, nasce con l’obiettivo di separare nettamente gli aspetti architetturali dalla user interface, semplificando la gestione dello stato applicativo e rendendo il codice molto più manutenibile, testabile e scalabile. ' },
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: 'Creare applicazioni React scalabili, testabili e semplici da mantenere utilizzando Redux per la gestione dello stato applicativo, applicando una netta separazione tra architettura dati e presentational layer'},
    { title: 'REQUISITI', text: 'Familiarità con l’utilizzo di React. Conoscere gli argomenti affrontati durante il corso <a href="../corso-react-core-concepts">React Core Concepts</a>.' },
  ],
  metadata: [

  ],
  carousels: {
    main: null,
    reviews: null
  },
  videos: {
    teaser1: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '8 ore (1g)' },
    { icon: faChartBar, label: 'Livello:', value: 'Avanzato' },
    { icon: faCode, label: 'Versione React:', value: '16.8.x' },
    { icon: faCode, label: 'Versione Redux:', value: '4.x' },
    { icon: faUser, label: 'Tipologia:', value: 'Corso in aula' },

  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'Un semplice esempio in Angular per il caricamento di dati tramite REST API e la loro visualizzazione sfruttando alcuni degli strumenti offerti dal framework:',
    video1: 'UXEvvLJhOBQ',
    bgColor: '#c3002f',
    items: [
      { text: 'Direttive del framework: ngIf e ngFor'},
      { text: 'HttpClient e comunicazione con REST API'},
      { text: 'Creazione custom types tramite l\'utilizzo di interfaccce'},
    ]
  },
  mode: null,
  program: {
    colLeft: [



      { type: 'title', value: 'Introduzione a Redux' },
      { value: 'Principi fondamentali', },
      { value: 'Immutable State', },
      { value: 'Pure vs Inpure functions', },
      { value: 'Containers vs Presentational components', },
      { value: 'Concetto di Store', },
      { value: 'Cosa sono reducer', },
      { value: 'Actions', },
      { value: 'Selectors e memoization', },

    ],
    colRight: [


      { type: 'title', value: 'Data architectures in Redux' },
      { value: 'La libreria / pattern React-Redux ', },
      { value: 'Creazione progetti React con Redux', },
      { value: 'Store, Action, Reducer', },
      { value: 'connect, mapStateToProps, mapDispatchToProps', },
      { value: 'Redux Hooks: useSelector, useDispatch, useStore', },
      { value: 'OffLine application e local storage', },
      { value: 'Debugging e Redux Chrome Dev tools', },
      { value: 'Reselect e il concetto di memoization', },

      { type: 'title', value: 'Redux Middlewares' },
      { value: 'Middleware e Enhancers', },
      { value: 'Async actions', },
      { value: 'Redux Thunk', },
      { value: 'Breve introduzione a Sagas e Redux Observable', },



    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '8 ore (1g)'},
      { label: 'Accesso ad Area educational', extra: 'Slide & Esercizi'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
    //campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
}
